<template>
    <button @click="exportExcel">
        <slot></slot>
    </button>
</template>

<script>
    import XLSX from 'xlsx/xlsx';
    export default {
        name: "vue-excel-xlsx-custom",
        props: {
            columns: {
                type: Array,
                default(){
  return [];
                } 
            },
            data: {
                type: Array,
                 default(){
  return [];
                } 
            },
            filename: {
                type: String,
                default: 'excel'
            },
            sheetname: {
                type: String,
                default: 'SheetName'
            }
        },
        methods: {
            exportExcel() {
                let createXLSLFormatObj = [];
                let newXlsHeader = [];
                let vm = this;
                if (vm.columns.length === 0){
                    return;
                }
                if (vm.data.length === 0){
                    return;
                }
                vm.columns.forEach(function(value) {
                    newXlsHeader.push(value.label);
                });
                createXLSLFormatObj.push(newXlsHeader);
                vm.data.forEach(function(value) {
                    let innerRowData = [];
                   vm.columns.forEach(function(val) {
                        if (val.dataFormat && typeof val.dataFormat === 'function') {
                            innerRowData.push(val.dataFormat(val.field?value[val.field]:value));
                        }else {
                            innerRowData.push(value[val.field]);
                        }
                    });
                    createXLSLFormatObj.push(innerRowData);
                });
                let filename = vm.filename + ".xlsx";
                let ws_name = vm.sheetname;
                let wb = XLSX.utils.book_new(),
                    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, filename);
            }
        }
    }
</script>