<template>
    <div>
        <div class="row review-component">
            <b-card class="col-lg-12 main-content">
                <div class="panel-list" v-show="Request != null">
                    <div class="row">
                        <div class="col-lg-12 text-right mb-3">
                            <!-- <b-button
              class="btn custom-button"
              text="Download As Excel"
              @click="downloadAsExcel()"
              variant="outline-primary"
            >
              Download As Excel
            </b-button> -->
                            <vue-excel-xlsx-custom :data="Request.Products" :columns="fields" :filename="pageTitle"
                                :sheetname="'Products'" class="btn custom-button" text="Download As Excel"
                                variant="outline-primary" v-if="!IsAuditTrail">
                                <b-button class="btn custom-button" text="Download As Excel" variant="outline-primary">
                                    Download As Excel
                                </b-button>
                            </vue-excel-xlsx-custom>
                        </div>
                        <div class="col-lg-12">
                            <b-table ref="productsTable" id="product-requests" :current-page="currentPage" :items="rows"
                                :fields="fields" no-local-sorting=true show-empty striped hover responsive>
                                <template v-slot:cell(ProductName)="data">
                                    <div class="product-name" :class="{ 'has-exception': isCommissionException(data.item) }">
                                        {{ data.item.ProductName }}</div>
                                </template>
                                <template v-slot:cell(ProductCode)="data">
                                    <div class="text-left" v-if="IsComplete">
                                        <b-form-input class="form-control input-sm" :maxlength="3" :minlength="2"
                                            v-model="data.item.ProductCode" :disabled="data.item.ExistingProduct"
                                            v-bind:class="{
                                                errorborder: $v.Request && $v.Request.Products &&
                                                    $v.Request.Products.$each.$iter[
                                                        GetRowIndex(data.index)
                                                    ].ProductCode.$invalid && $v.Request && $v.Request.Products &&
                                                    $v.Request.Products.$each.$iter[
                                                        GetRowIndex(data.index)
                                                    ].ProductCode.$dirty,
                                                noterrorborder: $v.Request && $v.Request.Products ?
                                                    !$v.Request.Products.$each.$iter[
                                                        GetRowIndex(data.index)
                                                    ].ProductCode.$invalid : true,
                                            }"></b-form-input>
                                        <div class="error-message-format" v-if="$v.Request && $v.Request.Products &&
                                            !$v.Request.Products.$each.$iter[
                                                GetRowIndex(data.index)
                                            ].ProductCode.required && $v.Request.Products.$each.$iter[
                                                GetRowIndex(data.index)
                                            ].ProductCode.$dirty
                                            ">
                                            Required field
                                        </div>
                                        <div class="error-message-format" v-if="$v.Request && $v.Request.Products &&
                                            !$v.Request.Products.$each.$iter[
                                                GetRowIndex(data.index)
                                            ].ProductCode.maxLength && $v.Request.Products.$each.$iter[
                                                GetRowIndex(data.index)
                                            ].ProductCode.$dirty
                                            ">
                                            Product Code should be
                                            {{
                                                $v.Request.Products.$each.$iter[GetRowIndex(data.index)]
                                                    .ProductCode.$params.maxLength.max
                                            }}
                                            characters max.
                                        </div>
                                        <div class="error-message-format" v-if="$v.Request && $v.Request.Products &&
                                                !$v.Request.Products.$each.$iter[
                                                    GetRowIndex(data.index)
                                                ].ProductCode.minLength && $v.Request.Products.$each.$iter[
                                                    GetRowIndex(data.index)
                                                ].ProductCode.$dirty
                                                ">
                                            Input must be at least
                                            {{
                                                $v.Request.Products.$each.$iter[GetRowIndex(data.index)]
                                                    .ProductCode.$params.minLength.min
                                            }}
                                            characters
                                        </div>
                                        <div class="error-message-format" v-if="$v.Request && $v.Request.Products &&
                                                !$v.Request.Products.$each.$iter[
                                                    GetRowIndex(data.index)
                                                ].ProductCode.unique && $v.Request.Products.$each.$iter[
                                                    GetRowIndex(data.index)
                                                ].ProductCode.$dirty
                                                ">
                                            This product code already exists.
                                        </div>
                                    </div>
                                    <div class="custom-product-code" v-else
                                        :class="{ 'has-exception': isCommissionException(data.item) }">
                                        {{ formatProductCode(data.item) }}
                                    </div>
                                </template>
                                <template v-slot:cell(ProductMediaDetails)="data">
                                    <div class="text-left format-media" style="font-size: 12px; line-height: 12px"
                                        :class="{ 'has-exception': isCommissionException(data.item) }">
                                        <div v-html="formatMedia(data.item)"></div>
                                    </div>
                                </template>
                                <template v-slot:cell(ProductBillingAddress)="data">
                                    <div class="text-left product-address-ex"
                                        :class="{ 'has-exception': isCommissionException(data.item) }">
                                        {{ formatAddressException(data.item) }}
                                    </div>
                                </template>
                                <template v-slot:cell(CommissionException)="data">
                                    <div class="text-left product-commission-ex"
                                        :class="{ 'has-exception': isCommissionException(data.item) }">
                                        {{ formatCommissionException(data.item) }}
                                        <div v-if="isCommissionException(data.item)">
                                            <upload-file-holder :Files="data.item.Files"></upload-file-holder>
                                            <!-- <button class="btn btn-link" @click="GetCommissionSupportFiles(data.item)">Commission Support Files</button> -->
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:cell(Agency)="data">
                                    <div class="text-left product-commission-ex agency"
                                        :class="{ 'has-exception': isCommissionException(data.item) }">
                                        {{ formatAgency(data.item) }}
                                    </div>
                                </template>
                                <template slot="custom-foot" v-if="IsPaginationRequired">
                                    <b-tr>
                                        <b-td colspan="5" style="padding-top: 14px !important">
                                            <Pagination style="float: right" aria-controls="product-requests"
                                                v-model="currentPage" :total-rows="TotalRows" first-number last-number />
                                        </b-td>
                                    </b-tr>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
const moment = require("moment");
import VueExcelXlsxCustom from "./../Product/CustomVueExcelXlsx.vue";
import {
    requiredIf,
    maxLength,
    minLength
} from "vuelidate/lib/validators";
import service from "../../services/Dropdowns";
import UploadFileHolder from '@/components/Common/UploadFileHolder';
export default {
    name: "ProductOverview",
    components: {
        VueExcelXlsxCustom,
        'UploadFileHolder': UploadFileHolder
    },
    validations: {
        Request: {
            Products: {
                $each: {
                    ProductCode: {
                        required: requiredIf(function () {
                            return this.IsComplete == true;
                        }),
                        maxLength: maxLength(3),
                        minLength: minLength(2),
                        unique(value) {
                            return (
                                !this.Request.Products ||
                                this.Request.Products.filter((val) => val.ProductCode == value)
                                    .length < 2
                            );
                        },
                    },
                },
            },
        },
    },
    created() {
        this.LoadAgency();
    },
    props: {
        IsComplete: {
            required: false,
            type: Boolean,
            default: false,
        },
        Request: {
            required: false,
            type: Object,
            default() {
                return {
                    Products: []
                }
            }
        },
        ID: {
            type: Number,
            required: false,
            default: 0,
        },
        IsAuditTrail: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            USACountry: "United States",
            CountryUnitedStatesValue: "78d70193e1eb493dafcf3f261b7759ef",
            BillingStreetAddressLength: 26,
            fields: this.buildColumns(),
            perPage: 10,
            currentPage: 1,
            rows: [],
            AgencyList: [],
        };
    },
    watch: {
        currentPage: function (newVal) {
            if (newVal && parseInt(newVal) > 0) {
                if (this.TotalRows > (parseInt(newVal) - 1) * this.perPage) {
                    this.rows = this.Request.Products.slice(
                        (parseInt(this.currentPage) - 1) * this.perPage,
                        parseInt(this.currentPage) * this.perPage
                    );
                }
            }
        },
        Request: function () {
            if (this.currentPage && parseInt(this.currentPage) > 0) {
                if (this.TotalRows > (parseInt(this.currentPage) - 1) * this.perPage) {
                    this.rows = this.Request.Products.slice(
                        (parseInt(this.currentPage) - 1) * this.perPage,
                        parseInt(this.currentPage) * this.perPage
                    );
                }
            }
        },
    },
    mounted() { },
    computed: {
        pageTitle() {
            return this.Request && this.Request.Products ?
                `${this.Request.ClientName} | ${this.Request.Products.length} Products` :
                "";
        },
        TotalRows() {
            return this.Request != null && this.Request.Products != null ?
                this.Request.Products.length :
                0;
        },
        IsPaginationRequired() {
            return (
                this.TotalRows != null &&
                parseInt(this.TotalRows) > parseInt(this.perPage)
            );
        },
        BillingPartyNotTimeOnly() {
            let timeOnlyOptions = [
                "Time Only (Client Related Time MED)",
                "Time Only (Internal Project Related Time ADM)",
            ];
            //let timeOnlySelected=timeOnlyOptions.includes(this.BillingInfo.BillingParty);
            return !(
                this.ClientRequest &&
                timeOnlyOptions.includes(this.ClientRequest.BillingParty)
            );
        },
        IsBillingCountryUSA() {
            return this.ClientRequest.Country == this.USACountry;
        },
    },
    methods: {
        async LoadAgency() {
            service
                .getAgencyDropdownValues("")
                .then((res) => {
                    this.AgencyList = res.data;
                    if (this.currentPage && parseInt(this.currentPage) > 0) {
                        if (this.TotalRows > (parseInt(this.currentPage) - 1) * this.perPage) {
                            this.rows = this.Request.Products.slice(
                                (parseInt(this.currentPage) - 1) * this.perPage,
                                parseInt(this.currentPage) * this.perPage
                            );
                        }
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        GetRowIndex(index) {
            return index + (this.currentPage - 1) * this.perPage;
        },
        groupBy(arr, prop) {
            const map = new Map(Array.from(arr, (obj) => [obj[prop],
            []
            ]));
            arr.forEach((obj) => map.get(obj[prop]).push(obj));
            return Array.from(map.values());
        },
        formatAddressException(row) {
            let content = "";
            if (
                row.ProductBillingAddress != null &&
                row.ProductBillingAddress.Country != null
            ) {
                content = `${row.ProductBillingAddress.AddressLine1}, ${row.ProductBillingAddress.AddressLine2}, ${row.ProductBillingAddress.CountryName}`;
                if (
                    row.ProductBillingAddress.Country == this.CountryUnitedStatesValue
                ) {
                    content = `${row.ProductBillingAddress.BillingStreetAddress}, ${row.ProductBillingAddress.City}, ${row.ProductBillingAddress.StateName},${row.ProductBillingAddress.ZipCode}`;
                }
            } else {
                content = "—";
            }
            return content;
        },
        formatAddressExceptionForDownload(row) {
            let content = "";
            if (row != null && row.Country != null) {
                content = `${row.AddressLine1}, ${row.AddressLine2}, ${row.CountryName}`;
                if (row.Country == this.CountryUnitedStatesValue) {
                    content = `${row.BillingStreetAddress}, ${row.City}, ${row.StateName},${row.ZipCode}`;
                }
            } else {
                content = "—";
            }
            return content;
        },
        isCommissionException(row) {
            return row.CommissionBasisID != null || row.FeeOnly == true || row.TimeOnly == true;
        },
        formatAgency(row) {
            return this.GetAgencyName(row.Agency);
        },
        formatMediaTypesForGrid(row) {
            if (row.ProductMediaDetails != null && row.ProductMediaDetails.length > 0) {
                let medias = row.ProductMediaDetails.map(media => {
                    return media.MediaType;
                }).join(",");
                return medias;
            } else {
                return "";
            }
        },
        formatCommissionException(row) {
            let content = "";
            if (row.CommissionBasisID != null) {
                content += row.CommissionBasisID == 1 ? "Net: " : "Gross: ";
                content += ` ${row.CommissionPercentage} %,Media: ${this.formatMediaTypesForGrid(row)}`;

            } else {
                if (row.FeeOnly == true) {
                    content = `Fee Only,Media: ${this.formatMediaTypesForGrid(row)}`;
                } else if (row.TimeOnly == true) {
                    content = `Time Only,Media: ${this.formatMediaTypesForGrid(row)}`;
                } else {
                    content = "—";
                }
            }
            return content;
        },
        formatAgencyForDownload(row) {
            let content = "";
            if (row.Agency != null) {
                content = this.GetAgencyName(row.Agency);
            }
            return content;
        },
        GetAgencyName(agency) {
            let name = "";
            if (agency) {
                let agencyIndex = this.AgencyList.findIndex((item) => {
                    return item.value == agency;
                })
                if (agencyIndex > -1) {
                    name = this.AgencyList[agencyIndex].label;
                } else {
                    name = "—";
                }
            } else {
                name = "—";
            }
            return name;
        },
        formatCommissionExceptionForDownload(row) {
            let content = "";
            if (row.CommissionBasisID != null) {
                content += row.CommissionBasisID == 1 ? "Net: " : "Gross: ";
                content += ` ${row.CommissionPercentage} %`;
            } else {
                if (row.FeeOnly == true) {
                    content = "Fee Only";
                } else {
                    content = "—";
                }
            }
            return content;
        },
        formatMedia(row) {
            if (
                row.ProductMediaDetails != null &&
                row.ProductMediaDetails.length > 0
            ) {
                let group = this.groupBy(row.ProductMediaDetails, "MediaCategory");
                let html = "";
                group.forEach((s) => {
                    html += `<span> ${s[0].MediaCategory} - `;
                    let medias = [];
                    s.forEach((media) => {
                        medias.push(`${media.MediaType}`);
                    });
                    html += `${medias.join(",")} </span>`;
                });
                return html;
            } else {
                return "—";
            }
        },
        formatProductCode(row) {
            return this.IsAuditTrail == true && row.CompletedCode ? row.CompletedCode : row.ProductCode;
        },
        mediaDetailsFormat(ProductMediaDetails) {
            if (ProductMediaDetails != null && ProductMediaDetails.length > 0) {
                let group = this.groupBy(ProductMediaDetails, "MediaCategory");
                let html = "";
                group.forEach((s) => {
                    html += `${s[0].MediaCategory} - `;
                    let medias = [];
                    s.forEach((media) => {
                        medias.push(`${media.MediaType}`);
                    });
                    html += `${medias.join(",")} \n`;
                });
                return html;
            } else {
                return "—";
            }
        },
        buildColumns() {
            let columns = [{
                key: "ProductName",
                field: "ProductName",
                label: "Product Name",
                sortable: true,
            },
            {
                key: "ProductCode",
                field: "ProductCode",
                label: "Code",
                sortable: true,
            },
            {
                key: "ProductMediaDetails",
                field: "ProductMediaDetails",
                label: "Media",
                sortable: true,
                dataFormat: this.mediaDetailsFormat,
            },
            {
                key: "ProductBillingAddress",
                label: "Address Exception",
                field: "ProductBillingAddress",
                sortable: true,
                dataFormat: this.formatAddressExceptionForDownload,
            },
            {
                key: "CommissionException",
                label: "Commission Exception",
                field: "",
                sortable: true,
                dataFormat: this.formatCommissionExceptionForDownload,
            },
            {
                key: "ID",
                label: "id",
                visible: false,
                thClass: "d-none",
                tdClass: "d-none",
            },
            {
                key: "Agency",
                label: "Agency",
                field: "",
                sortable: true,
                dataFormat: this.formatAgencyForDownload,
            },
            {
                key: "UDEF1",
                label: "UDEF 1",
                field: "",
                sortable: true,
            },
            {
                key: "UDEF2",
                label: "UDEF 2",
                field: "",
                sortable: true,
            },
            ];

            return columns;
        },

        downloadAsExcel() {
            this.$store
                .dispatch("product/DownloadList", this.GetGridOptions())
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `ProductRequests_${moment().format(
                        "MMDDYYYYmmhhss"
                    )}.xlsx`;
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error downloading requests.`
                    );
                });
        },
        IsProductExceptionsAdded(product) {
            return (
                product != null &&
                (product.ProductBillingAddress != null ||
                    product.CommissionBasisID != null ||
                    product.FeeOnly != null ||
                    product.TimeOnly != null)
            );
        },
        IsBillingExceptionAdded(product) {
            return product != null && product.ProductBillingAddress != null;
        },
        IsCommissionExceptionAdded(product) {
            return (
                product != null &&
                (product.CommissionBasisID != null || product.FeeOnly != null)
            );
        },
        GetCommissionException(product) {
            let html = "";
            if (
                product.CommissionBasisID != null ||
                product.FeeOnly != null ||
                product.TimeOnly != null
            ) {
                if (product.TimeOnly) {
                    html += `Time Only`;
                } else if (product.CommissionBasisID == 1) {
                    html += `Commission Basis: Net - ${product.CommissionPercentage}%`;
                } else if (product.CommissionBasisID == 2) {
                    html += `Commission Basis: Gross - ${product.CommissionPercentage}%`;
                } else if (product.FeeOnly) {
                    html += `Fee Only`;
                }
            }
            return html;
        },
        GetBillingException(product) {
            let html = "";
            if (product.ProductBillingAddress != null) {
                if (product.ProductBillingAddress.Country != this.USACountry) {
                    html += `Billing Address: ${product.ProductBillingAddress.AddressLine1} ${product.ProductBillingAddress.AddressLine2}`;
                } else {
                    let billingAdddress =
                        product.ProductBillingAddress.BillingStreetAddress.length >
                            this.BillingStreetAddressLength &&
                            product.ProductBillingAddress.TruncatedBillingStreetAddress ?
                            product.ProductBillingAddress.TruncatedBillingStreetAddress :
                            product.ProductBillingAddress.BillingStreetAddress;
                    html += `Billing Address: ${billingAdddress}, ${product.ProductBillingAddress.City},${product.ProductBillingAddress.State},${product.ProductBillingAddress.ZipCode}`;
                }
            }
            return html;
        },
    },
};
</script>

<style>
.product-address-ex {
    line-height: 1.2;
}

.format-media div>span {
    display: block;
    line-height: 1.2;
    /* height: 14px; */
}

/* div.format-media:not(.has-exception) div>span,
div.product-commission-ex:not(.has-exception),
div.product-address-ex:not(.has-exception),
div.custom-product-code:not(.has-exception),
div.product-name:not(.has-exception) {
    opacity: 0.49;
} */
.product-commission-ex.agency {
    line-height: 1.2;
}

div.product-commission-ex:not(.has-exception) div.product-name:not(.has-exception) {
    opacity: 0.49;
}

.panel-list:not(.media-dropdowns) {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.file-ref {
    color: #007bff !important;
}

.panel-list:not(.media-dropdowns) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
}

.panel-content.row>div>label:first-child {
    color: #2c3865 !important;
    font-family: "Open Sans" !important;
    font-size: 12px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div>*:not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}

.request-status {
    background-color: rgba(216, 216, 216, 0.2);
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    height: 135px;
    border-radius: 5px;
}

.status-bar {
    line-height: 2;
    color: #d5d7e0;
}

.request-status .status {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .date {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #a2a9ad;
}

.request-status .updatedby {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .comments {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #00a1d2;
}</style>
