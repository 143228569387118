var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row review-component"},[_c('b-card',{staticClass:"col-lg-12 main-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.Request != null),expression:"Request != null"}],staticClass:"panel-list"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 text-right mb-3"},[(!_vm.IsAuditTrail)?_c('vue-excel-xlsx-custom',{staticClass:"btn custom-button",attrs:{"data":_vm.Request.Products,"columns":_vm.fields,"filename":_vm.pageTitle,"sheetname":'Products',"text":"Download As Excel","variant":"outline-primary"}},[_c('b-button',{staticClass:"btn custom-button",attrs:{"text":"Download As Excel","variant":"outline-primary"}},[_vm._v(" Download As Excel ")])],1):_vm._e()],1),_c('div',{staticClass:"col-lg-12"},[_c('b-table',{ref:"productsTable",attrs:{"id":"product-requests","current-page":_vm.currentPage,"items":_vm.rows,"fields":_vm.fields,"no-local-sorting":"true","show-empty":"","striped":"","hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(ProductName)",fn:function(data){return [_c('div',{staticClass:"product-name",class:{ 'has-exception': _vm.isCommissionException(data.item) }},[_vm._v(" "+_vm._s(data.item.ProductName))])]}},{key:"cell(ProductCode)",fn:function(data){return [(_vm.IsComplete)?_c('div',{staticClass:"text-left"},[_c('b-form-input',{staticClass:"form-control input-sm",class:{
                                            errorborder: _vm.$v.Request && _vm.$v.Request.Products &&
                                                _vm.$v.Request.Products.$each.$iter[
                                                    _vm.GetRowIndex(data.index)
                                                ].ProductCode.$invalid && _vm.$v.Request && _vm.$v.Request.Products &&
                                                _vm.$v.Request.Products.$each.$iter[
                                                    _vm.GetRowIndex(data.index)
                                                ].ProductCode.$dirty,
                                            noterrorborder: _vm.$v.Request && _vm.$v.Request.Products ?
                                                !_vm.$v.Request.Products.$each.$iter[
                                                    _vm.GetRowIndex(data.index)
                                                ].ProductCode.$invalid : true,
                                        },attrs:{"maxlength":3,"minlength":2,"disabled":data.item.ExistingProduct},model:{value:(data.item.ProductCode),callback:function ($$v) {_vm.$set(data.item, "ProductCode", $$v)},expression:"data.item.ProductCode"}}),(_vm.$v.Request && _vm.$v.Request.Products &&
                                        !_vm.$v.Request.Products.$each.$iter[
                                            _vm.GetRowIndex(data.index)
                                        ].ProductCode.required && _vm.$v.Request.Products.$each.$iter[
                                            _vm.GetRowIndex(data.index)
                                        ].ProductCode.$dirty
                                        )?_c('div',{staticClass:"error-message-format"},[_vm._v(" Required field ")]):_vm._e(),(_vm.$v.Request && _vm.$v.Request.Products &&
                                        !_vm.$v.Request.Products.$each.$iter[
                                            _vm.GetRowIndex(data.index)
                                        ].ProductCode.maxLength && _vm.$v.Request.Products.$each.$iter[
                                            _vm.GetRowIndex(data.index)
                                        ].ProductCode.$dirty
                                        )?_c('div',{staticClass:"error-message-format"},[_vm._v(" Product Code should be "+_vm._s(_vm.$v.Request.Products.$each.$iter[_vm.GetRowIndex(data.index)] .ProductCode.$params.maxLength.max)+" characters max. ")]):_vm._e(),(_vm.$v.Request && _vm.$v.Request.Products &&
                                            !_vm.$v.Request.Products.$each.$iter[
                                                _vm.GetRowIndex(data.index)
                                            ].ProductCode.minLength && _vm.$v.Request.Products.$each.$iter[
                                                _vm.GetRowIndex(data.index)
                                            ].ProductCode.$dirty
                                            )?_c('div',{staticClass:"error-message-format"},[_vm._v(" Input must be at least "+_vm._s(_vm.$v.Request.Products.$each.$iter[_vm.GetRowIndex(data.index)] .ProductCode.$params.minLength.min)+" characters ")]):_vm._e(),(_vm.$v.Request && _vm.$v.Request.Products &&
                                            !_vm.$v.Request.Products.$each.$iter[
                                                _vm.GetRowIndex(data.index)
                                            ].ProductCode.unique && _vm.$v.Request.Products.$each.$iter[
                                                _vm.GetRowIndex(data.index)
                                            ].ProductCode.$dirty
                                            )?_c('div',{staticClass:"error-message-format"},[_vm._v(" This product code already exists. ")]):_vm._e()],1):_c('div',{staticClass:"custom-product-code",class:{ 'has-exception': _vm.isCommissionException(data.item) }},[_vm._v(" "+_vm._s(_vm.formatProductCode(data.item))+" ")])]}},{key:"cell(ProductMediaDetails)",fn:function(data){return [_c('div',{staticClass:"text-left format-media",class:{ 'has-exception': _vm.isCommissionException(data.item) },staticStyle:{"font-size":"12px","line-height":"12px"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatMedia(data.item))}})])]}},{key:"cell(ProductBillingAddress)",fn:function(data){return [_c('div',{staticClass:"text-left product-address-ex",class:{ 'has-exception': _vm.isCommissionException(data.item) }},[_vm._v(" "+_vm._s(_vm.formatAddressException(data.item))+" ")])]}},{key:"cell(CommissionException)",fn:function(data){return [_c('div',{staticClass:"text-left product-commission-ex",class:{ 'has-exception': _vm.isCommissionException(data.item) }},[_vm._v(" "+_vm._s(_vm.formatCommissionException(data.item))+" "),(_vm.isCommissionException(data.item))?_c('div',[_c('upload-file-holder',{attrs:{"Files":data.item.Files}})],1):_vm._e()])]}},{key:"cell(Agency)",fn:function(data){return [_c('div',{staticClass:"text-left product-commission-ex agency",class:{ 'has-exception': _vm.isCommissionException(data.item) }},[_vm._v(" "+_vm._s(_vm.formatAgency(data.item))+" ")])]}}])},[(_vm.IsPaginationRequired)?_c('template',{slot:"custom-foot"},[_c('b-tr',[_c('b-td',{staticStyle:{"padding-top":"14px !important"},attrs:{"colspan":"5"}},[_c('Pagination',{staticStyle:{"float":"right"},attrs:{"aria-controls":"product-requests","total-rows":_vm.TotalRows,"first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1):_vm._e()],2)],1)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }